import React from 'react';

function AboutUs() {
  return (
    <div className="container p-2">
      <h3>About Detfix: Powering Your Business with Innovative Software Solutions</h3>
      <p>
        Welcome to Detfix Software Private Limited, your trusted partner in digital transformation. At Detfix, we
        are dedicated to helping businesses of all sizes harness the power of cutting-edge software solutions to
        thrive in today’s competitive landscape. With a focus on innovation, quality, and client satisfaction, Detfix
        is redefining how businesses operate, grow, and achieve success.
      </p>
      <p>
        Detfix was founded on the principles of excellence and innovation. Since our inception in 2020, we have been
        driven by a singular mission: to deliver tailor-made software solutions that empower businesses to reach new
        heights. From startups to established enterprises, our diverse range of clients trusts us to deliver software
        solutions that are robust, scalable, and efficient.
      </p>
      <h4>Our Vision</h4>
      <p>
        At Detfix, we envision a future where businesses, regardless of size, have access to affordable and
        high-quality software solutions that transform the way they operate. We aim to be at the forefront of the
        digital revolution, providing businesses with the tools they need to innovate, scale, and thrive in an
        ever-evolving technological landscape.
      </p>
      <h4>Our Mission</h4>
      <p>
        Our mission is to provide world-class software solutions that empower businesses to streamline operations,
        enhance customer experiences, and drive growth. We are committed to offering cutting-edge technology combined
        with exceptional service, ensuring that every solution we provide is tailored to the unique needs and goals of
        our clients.
      </p>
      <h4>What We Do</h4>
      <p>
        Detfix Software Private Limited specializes in providing comprehensive eCommerce solutions, custom software
        development, and cloud-based services. We offer a wide range of services that cater to businesses at every
        stage of their growth journey. Whether you're launching your first eCommerce store, seeking to improve
        operational efficiency with a custom ERP system, or migrating to a cloud infrastructure, we are here to help.
      </p>
      <h5>eCommerce Solutions</h5>
      <p>
        In the fast-paced world of eCommerce, having a reliable, scalable, and user-friendly platform is crucial to
        your success. Detfix offers end-to-end eCommerce solutions designed to boost your online sales, streamline
        operations, and enhance the customer experience. From secure payment gateways to custom storefront designs,
        we provide everything you need to succeed in the digital marketplace.
      </p>
      <h5>Custom Software Development</h5>
      <p>
        No two businesses are the same, and neither are their software needs. At Detfix, we specialize in developing
        custom software that is tailored to the specific requirements of your business. Whether you need a robust
        CRM, an inventory management system, or a bespoke mobile app, we deliver solutions that fit seamlessly into
        your operations and scale as your business grows.
      </p>
      <h5>Cloud-Based Solutions</h5>
      <p>
        In today’s digital landscape, cloud technology is a game-changer. Our cloud-based solutions enable businesses
        to operate more efficiently, scale faster, and reduce costs. From cloud infrastructure management to SaaS
        solutions, Detfix ensures your business is equipped with the tools and flexibility needed to stay ahead of
        the competition.
      </p>
      <h4>Why Choose Detfix?</h4>
      <h5>Client-Centric Approach</h5>
      <p>
        At Detfix, we place our clients at the heart of everything we do. Our client-centric approach means that we
        work closely with you to understand your unique business challenges and goals. This allows us to deliver
        solutions that not only meet your immediate needs but also support your long-term business objectives.
      </p>
      <h5>Expert Team</h5>
      <p>
        Our team of experienced developers, designers, and strategists are experts in their fields. We bring a wealth
        of knowledge and creativity to every project, ensuring that our solutions are innovative, reliable, and
        cutting-edge. With Detfix, you have access to a team that is committed to your success.
      </p>
      <h5>Commitment to Quality</h5>
      <p>
        Quality is at the core of everything we do at Detfix. From initial concept to final deployment, we ensure
        that every aspect of our solutions meets the highest standards of performance and reliability. Our rigorous
        testing and quality assurance processes ensure that you receive software that is both powerful and
        dependable.
      </p>
      <h5>Continuous Support and Maintenance</h5>
      <p>
        Our relationship with clients doesn’t end with project delivery. We provide ongoing support and maintenance
        to ensure that your software continues to function optimally as your business evolves. Whether it’s software
        updates, troubleshooting, or scaling solutions, we are here to provide the support you need.
      </p>
      <h4>Join the Detfix Revolution</h4>
      <p>
        At Detfix, we believe that software has the power to transform businesses. Our goal is to help you leverage
        technology to achieve your business objectives and stay ahead of the competition. We invite you to partner
        with us on your digital journey and experience the Detfix difference.
      </p>
      <p>
        Thank you for choosing Detfix Software Private Limited. Together, let's build a future where technology
        drives success, innovation leads growth, and businesses thrive in the digital age.
      </p>
    </div>
  );
}

export default AboutUs;
