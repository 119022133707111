import React from 'react';

function ShippingPolicy() {
  return (
    <div className='container py-4'>
      <h2 className='my-3'>Detfix - Shipping Policy</h2>
      <p>
        At Detfix, we understand that timely and reliable shipping is crucial when it comes to delivering quality products directly to your doorstep. Our Shipping Policy is designed to provide you with a transparent and seamless experience, ensuring that your eagerly awaited Detfix products reach you in pristine condition.
      </p>

      <h4>Shipping Partners and Coverage</h4>
      <p>
        Detfix collaborates with trusted shipping partners to offer a comprehensive and efficient delivery network. Whether you're located in a major city or a remote area, our shipping services extend across a wide geographic range. We are committed to bringing the Detfix experience to customers worldwide.
      </p>

      <h4>Processing Time</h4>
      <p>
        Upon placing your order with Detfix, our dedicated team promptly begins processing. We strive to dispatch your products quickly, with an average processing time of 1-2 business days. However, processing times may vary during peak seasons or promotional events.
      </p>

      <h4>Shipping Options</h4>
      <p>
        Detfix offers various shipping options to meet your needs. Standard shipping provides a cost-effective, reliable option, while expedited services are available for quicker delivery. Each shipping method is chosen with the utmost care to ensure the safe transit of your Detfix products.
      </p>

      <h4>Estimated Delivery Times</h4>
      <p>
        Detfix is committed to providing realistic and accurate estimated delivery times, which vary based on your location, selected shipping method, and any unforeseen circumstances. Estimated delivery times are displayed during checkout, helping you make an informed decision.
      </p>

      <h4>Order Tracking</h4>
      <p>
        We know how exciting it is to await your Detfix products. Once your order is dispatched, we provide a tracking number via email. You can monitor your package's journey in real-time, giving you peace of mind as your Detfix items make their way to you.
      </p>

      <h4>International Shipping</h4>
      <p>
        Detfix serves a global customer base, and our international shipping services are tailored to meet the needs of customers worldwide. International shipping times may vary depending on customs processes and local regulations, but rest assured, Detfix adheres to all international shipping guidelines to ensure smooth delivery.
      </p>

      <h4>Shipping Costs</h4>
      <p>
        Detfix strives to maintain fair and competitive shipping costs. Shipping fees are calculated based on the weight of your order, your chosen shipping method, and your delivery location. During checkout, you'll see the shipping costs before finalizing your purchase.
      </p>

      <h4>Address Accuracy</h4>
      <p>
        Providing accurate and complete shipping information is crucial for a seamless delivery process. Detfix cannot be held responsible for delays or issues arising from inaccurate address details. Please double-check your shipping information before confirming your order to ensure a smooth delivery.
      </p>

      <h4>Shipping Updates and Notifications</h4>
      <p>
        Communication is key at Detfix. You'll receive confirmation emails upon order placement, shipping confirmation with tracking information, and delivery updates to keep you informed every step of the way.
      </p>
    </div>
  );
}

export default ShippingPolicy;