import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="container py-4">
      <h2 className="text-center my-3">Privacy Policy</h2>
      <p>
        At Detfix Software Private Limited, we respect your privacy and are committed to protecting your personal
        information. This Privacy Policy outlines how we collect, use, and safeguard the information you provide when
        you use our eCommerce platform and services. By accessing our website and using our services, you agree to the
        terms outlined in this policy.
      </p>

      <h3>A. Information We Collect</h3>

      <h5>1. Personal Information</h5>
      <p>
        "Personal information" refers to any information that identifies you personally, such as your name, email
        address, phone number, billing and shipping information, payment details, and purchase history. We collect
        this information when you create an account, place an order, or interact with our services in any way.
      </p>

      <h5>2. Automatically Collected Information</h5>
      <p>
        We may also collect certain information automatically when you visit our website, including your IP address,
        browser type, operating system, device information, and browsing activity. This information is used to improve
        the functionality and performance of our platform and to better understand how users interact with our services.
      </p>

      <h5>3. Cookies and Tracking Technologies</h5>
      <p>
        Detfix uses cookies, web beacons, and similar tracking technologies to enhance your user experience, analyze
        website traffic, and personalize content and advertisements. Cookies are small text files stored on your device
        when you visit our website. You can manage your cookie preferences through your browser settings, but disabling
        cookies may affect your experience with certain features of our site.
      </p>

      <h3>B. How We Use Your Information</h3>

      <h5>1. To Provide and Improve Our Services</h5>
      <p>
        We use the personal information we collect to process orders, deliver products, and provide customer support.
        We also use this information to improve our eCommerce platform, develop new products and services, and enhance
        your overall experience.
      </p>

      <h5>2. For Payment Processing</h5>
      <p>
        Your payment information is used to process transactions and ensure the secure completion of purchases. We use
        third-party payment gateways to handle payments, and these providers are subject to their own privacy policies.
      </p>

      <h5>3. Marketing and Promotional Communications</h5>
      <p>
        With your consent, we may use your contact information to send you promotional emails, newsletters, and updates
        about new products, special offers, or other relevant information. You can opt out of receiving marketing
        communications at any time by following the unsubscribe instructions provided in the emails.
      </p>

      <h5>4. To Comply with Legal Obligations</h5>
      <p>
        In certain cases, we may need to use your personal information to comply with legal and regulatory
        requirements, such as tax filings or responding to lawful requests from public authorities.
      </p>

      <h3>C. How We Share Your Information</h3>

      <h5>1. Service Providers</h5>
      <p>
        We may share your personal information with third-party service providers who assist us with various tasks,
        such as payment processing, order fulfillment, website hosting, and data analysis. These providers are
        contractually obligated to handle your data securely and in accordance with this Privacy Policy.
      </p>

      <h5>2. Business Transfers</h5>
      <p>
        In the event of a merger, acquisition, or sale of assets, your personal information may be transferred as part
        of the business transaction. We will notify you of any such transfer and provide you with the option to opt out
        of continued data processing if required by applicable laws.
      </p>

      <h5>3. Legal Requirements</h5>
      <p>
        We may disclose your personal information if required to do so by law or in response to a valid legal request
        from a government authority.
      </p>

      <h3>D. Your Rights and Choices</h3>

      <h5>1. Access and Correction</h5>
      <p>
        You have the right to access, update, or correct the personal information we hold about you. You can manage
        your account information directly through our website or contact our support team for assistance.
      </p>

      <h5>2. Data Deletion</h5>
      <p>
        If you wish to delete your account or request the removal of your personal information from our systems, you
        can contact us, and we will take the necessary steps to honor your request, subject to any legal obligations we
        may have to retain the data.
      </p>

      <h5>3. Marketing Preferences</h5>
      <p>
        You can opt out of receiving marketing emails and other promotional communications by following the
        instructions provided in those messages or by updating your preferences in your account settings.
      </p>

      <h3>E. Data Security</h3>
      <p>
        We take the security of your personal information seriously and implement appropriate technical and
        organizational measures to protect it from unauthorized access, loss, misuse, or alteration. However, no
        transmission of data over the internet is entirely secure, and we cannot guarantee the security of your
        information during transit.
      </p>

      <h3>F. Changes to this Privacy Policy</h3>
      <p>
        We may update this Privacy Policy from time to time to reflect changes in our practices or legal obligations.
        Any updates will be posted on this page, and we will notify you of significant changes via email or a notice on
        our website. Please review this policy periodically to stay informed about how we are protecting your
        information.
      </p>

      <h3>G. Contact Us</h3>
      <p>
        If you have any questions or concerns about this Privacy Policy or how we handle your personal information,
        please contact us at <a href="mailto:detfixtech@gmail.com" style={{textDecoration:'none', color:'#353535'}} className='privacy_link'>detfixtech@gmail.com</a>.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
